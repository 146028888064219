import {
  AlignItems,
  Box,
  FontWeight,
  Glyph,
  Icon,
  Menu,
  Space,
  Text,
  useTheme,
} from "@gocardless/flux-react";
import { routerPush } from "src/common/routing";
import { TrackingEvent } from "src/common/trackingEvents";
import { useSegment } from "src/technical-integrations/segment/useSegment";
import { MultiAccountTrackingContextValues } from "src/components/routes/Accounts/constants";

import { menuItemStyle } from "../OrganisationSwitcher.style";
import { MenuItemProps } from "../OrganisationSwitcherMenu";

interface GeneralMenuItemProps {
  menuItemProps: MenuItemProps;
  disabled?: boolean;
  useCustomHoverStyle?: boolean;
}

const getTrackingEvent = (menuItemId: MenuItemProps["id"]) => {
  switch (menuItemId) {
    case "view-accounts":
      return TrackingEvent.MULTI_ACCOUNT_VIEW_ALL_ACCOUNTS_CTA_CLICKED;
    case "manage-teams":
      return TrackingEvent.MULTI_ACCOUNT_MANAGE_TEAMS_CTA_CLICKED;
    case "create-account":
      return TrackingEvent.MULTI_ACCOUNT_CREATE_ACCOUNT_CTA_CLICKED;
  }
};

export const GeneralMenuItem: React.FC<GeneralMenuItemProps> = ({
  menuItemProps: { id, icon, title, route },
  disabled = false,
  useCustomHoverStyle = false,
}) => {
  const { theme } = useTheme();
  const { sendEvent } = useSegment();

  const handleClick = () => {
    const eventName = getTrackingEvent(id);

    routerPush({ route });
    sendEvent(eventName, {
      ctx: MultiAccountTrackingContextValues.AccountSwitcher,
    });
  };

  return (
    <Menu.Button
      onClick={handleClick}
      css={menuItemStyle(theme, { useCustomHoverStyle })}
      disabled={disabled}
    >
      <Box
        layout="flex"
        alignItems={AlignItems.Center}
        gutterH={icon === Glyph.Add ? 0.25 : 0.5}
      >
        <Icon size="12px" name={icon} />
        <Space h={1} layout="inline" />
        <Text weight={FontWeight.SemiBold}>{title}</Text>
      </Box>
    </Menu.Button>
  );
};

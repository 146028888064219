import Cookies from "js-cookie";
import {
  IntervalUnit,
  PayerThemeResource,
  DayOfWeek,
  Month,
} from "@gocardless/api/dashboard/types";
import { Currency } from "src/common/currencies";
import { CookieStorage } from "src/common/constants/storage";
import { PaymentType } from "src/components/routes/SetupPayments/common/type";

export interface PaymentBuilderCookie {
  paymentName?: string;
  logoSrc?: string | null;
  merchantName?: string;
  amount?: number;
  currency?: Currency;
  paymentType?: PaymentType;
  intervalUnit?: IntervalUnit;
  interval?: number;
  url?: string;
  payerTheme?: PayerThemeResource;
  dayOfWeek?: DayOfWeek | null;
  dayOfMonth?: string | null;
  month?: Month | null;
  count?: number | null;
}

/**
 * Util for Spark's "Experience the product" experiment
 * https://gocardless.atlassian.net/browse/SPK-1139
 */
export const getPaymentBuilderData = (): PaymentBuilderCookie | null => {
  const data = Cookies.get(CookieStorage.PaymentBuilder);
  if (data) {
    return JSON.parse(data) as PaymentBuilderCookie;
  }
  return null;
};

export const paymentBuilderKey = "gc.setup-payments.experience-product";

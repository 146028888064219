import { useUserListOrganisationsSelf } from "@gocardless/api/dashboard/user";
import { useOrganisation } from "src/queries/organisation";

export const useOrgSwitcherData = () => {
  const loggedInOrganisation = useOrganisation() ?? {};

  const {
    data: { list_organisations: organisationsList } = {},
    isLoading: isLoadingListOrganisations,
    error: listOrganisationsError,
  } = useUserListOrganisationsSelf({
    limit: 500,
  });

  const parentOrganisation =
    organisationsList?.find((org) => org.depth === 0) ?? {};

  const currentOrganisation =
    organisationsList?.find((org) => org.id === loggedInOrganisation.id) ?? {};

  const loggedIntoParentOrg =
    parentOrganisation?.id === currentOrganisation?.id;

  const sortedChildOrganisations =
    organisationsList
      ?.filter((org) => org.depth === 1 && org.id !== currentOrganisation?.id)
      .sort(
        (a, b) =>
          (a.nickname || a.name || "")?.localeCompare(
            b.nickname || a.name || ""
          )
      )
      .slice(0, loggedIntoParentOrg ? 3 : 2) ?? [];

  return {
    organisations: [
      ...(loggedIntoParentOrg ? [] : [parentOrganisation]),
      ...sortedChildOrganisations,
    ],
    loggedInOrganisation: currentOrganisation,
    parentOrganisation,
    isLoading: isLoadingListOrganisations,
    error: listOrganisationsError,
  };
};

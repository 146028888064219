export enum SharingMethod {
  QRCODE_SAVED = "qr_code_saved",
  LINK_COPIED = "link_copied",
  EMAIL = "email",
  CSV = "csv",
  MANUAL = "manual",
  ASSIGN_CUSTOMERS = "assign_customers",
}

export enum ButtonPosition {
  DROPDOWN = "dropdown",
  INPAGE = "inpage",
  SIDENAV = "sidenav",
}

export enum ReferralsShareInitiatedTarget {
  COPY_LINK = "copy_link",
  EMAIL = "email",
  WHATSAPP = "whatsapp",
  LINKEDIN = "linkedin",
  TWITTER = "twitter",
  FACEBOOK = "facebook",
  NATIVE = "native",
}

export enum ReferralsSource {
  POPUP = "popup",
  DOUBLE_REWARD = "double_reward",
  REFER_BUTTON = "refer_button",
}

export enum ReferralEmailSource {
  "Intercom" = 1,
  "Dashboard" = 2,
}

export enum ReferralEmailVariation {
  "WEEK_1" = 1,
  "WEEK_2" = 2,
  "WEEK_3" = 3,
  "WEEK_4" = 4,
  "Last_48_Hours" = 5,
  "Last_24_Hours" = 6,
}

export enum ReferralsInformationClickedTarget {
  HOW_IT_WORKS = "how_it_works",
  TERMS = "terms",
  REFER_NOW = "refer_now",
  HOW_TO_REFER = "how_to_refer",
  REWARD_TIMING = "reward_timing",
}

export enum PaymentLinkTypeEventAttr {
  ONE_OFF_DD = "one_off_dd",
  PLAN = "plan",
  DD = "dd",
}

export enum PaymentTypeEventAttr {
  ONE_OFF_PAYMENT = "one_off_payment",
  PLAN = "plan",
}

export enum AccountSetupVersion {
  FIRST_PAYMENT_JOURNEY = "first_payment_journey",
  LOW_RISK_ONBOARDING = "low_risk_onabording",
}

export enum LroEligibilityResponse {
  SUCCESS = "success",
  TIMEOUT = "timeout",
}

export enum SetuPayments {
  START_FROM_SCRATCH = "start_from_scratch",
  QUICK_START_TEMPLATES = "quick_start_templates",
  SMART_PAYMENT_RECOMMENDATIONS = "smart_payment_recommendations",
  LET_US_DO_THE_HARD_WORK = "let_us_do_the_hard_work",
  INTERESTED_YES = "interested_yes",
  INTERESTED_NO = "interested_no",
  INTERESTED_MAYBE = "interested_maybe",
  DIALOG_CLOSE = "dialog_close",
  SUBMIT_RESPONSE = "submit_response",
}

import Router from "next/router";
import { parse } from "query-string";
import { addAccessToken } from "@gocardless/api/utils/api";
import {
  cookieBasedTokenCreate,
  CookieBasedTokenCreateResponseBody,
} from "@gocardless/api/dashboard/cookie-based-token";
import {
  TemporaryAccessTokenCreateResponseBody,
  temporaryAccessTokenDisableSelf,
} from "@gocardless/api/dashboard/temporary-access-token";
import { AdminAccessTokenCreateResponseBody } from "@gocardless/api/staff/admin-access-token";
import { UserResource } from "@gocardless/api/dashboard/types";
import { useEffect, useState } from "react";
import { getConfig } from "src/common/config";
import { sessionTracking } from "src/technical-integrations/segmentAbs/sessionTracking";
import { resetSegmentAnalytics } from "src/technical-integrations/segmentAbs";
import { MFA_DELAYED_ITEM_KEY } from "src/components/authentication/mfa/useMFAEnforcementData";
import { resetDrift } from "src/technical-integrations/drift/Drift";
import { logoutUser } from "src/technical-integrations/zendesk/client/zendesk";
import { isZendeskFormsInterceptorRoute } from "src/technical-integrations/zendesk/helpers";

import { removeOrganisationIdFromResponseHeader } from "../api";
import {
  externalNavigation,
  PublicOnlyRoutes,
  Route,
  routerPush,
} from "../routing";
import { getItem, removeItem, setItem } from "../local-storage/local-storage";
import {
  matchesDynamicRoute,
  PublicOnlyDynamicRoutes,
} from "../routing/routes";

import { AccessToken } from "./types";

const GC_ACCESS_TOKEN_KEY = "gc.access.token";

export function getAccessToken(): AccessToken {
  return getItem(GC_ACCESS_TOKEN_KEY);
}

export async function logout({
  appendRedirectURL = true,
}: {
  appendRedirectURL?: boolean;
} = {}) {
  try {
    await temporaryAccessTokenDisableSelf();
  } catch {
    /* empty */
  }
  const {
    location: { pathname, search },
  } = window;
  if (!PublicOnlyRoutes.includes(pathname)) {
    resetSegmentAnalytics();
  }

  addAccessToken("");
  removeItem(GC_ACCESS_TOKEN_KEY);
  removeItem(MFA_DELAYED_ITEM_KEY);

  removeOrganisationIdFromResponseHeader();
  sessionTracking.deleteSession();

  removeItem("gc.top-banner-closed");
  resetDrift();
  logoutUser();
  /*
   * Send the current pathname and search query param to `redirect`
   * query string to be picked up after successful authentication
   * and redirected back to original route.
   */
  const redirectURL = `${pathname}${search}`;
  const redirectURLQuery = appendRedirectURL
    ? {
        redirect: redirectURL,
      }
    : undefined;

  /**
   * Checks match for dynamic public routes with router params
   */
  const isDynamicPublicRoute = matchesDynamicRoute(
    pathname,
    PublicOnlyDynamicRoutes
  );

  if (PublicOnlyRoutes.includes(pathname) || isDynamicPublicRoute) {
    return;
  }

  /*
   * We should not redirect the user to sign-in screen
   * when pathName matches Zendesk Forms Interceptor route.
   * The interceptor will handle the redirecting to external Zendesk url.
   */
  if (isZendeskFormsInterceptorRoute(pathname)) {
    return;
  }

  routerPush({
    route: Route.SignIn,
    queryParams: redirectURLQuery,
  });
}

type AccessTokenResponse =
  | TemporaryAccessTokenCreateResponseBody
  | AdminAccessTokenCreateResponseBody;

export function setAccessTokenFromApiResponse(apiResponse: {
  users?: UserResource;
  linked?: AccessTokenResponse;
}) {
  setAccessToken(apiResponse.linked);
}

export function setAccessToken(accessTokenResponse?: AccessTokenResponse) {
  if (accessTokenResponse === undefined) {
    return;
  }

  const accessToken =
    "temporary_access_tokens" in accessTokenResponse
      ? accessTokenResponse.temporary_access_tokens
      : "admin_access_tokens" in accessTokenResponse
        ? accessTokenResponse.admin_access_tokens
        : {};

  setItem(GC_ACCESS_TOKEN_KEY, accessToken);
  addAccessToken(accessToken?.token || "");
}

export const navigateHome = (queryParams?: { [key: string]: string }) => {
  if (getConfig().shared.enableDevelopmentRoute) {
    Router.push({ pathname: "/" });
  } else {
    routerPush({ route: Route.Home, queryParams });
  }
};

export const checkForCookieBasedToken = async () => {
  try {
    const data = await cookieBasedTokenCreate();
    if (!data) {
      return;
    }

    const tokenResponse = data as CookieBasedTokenCreateResponseBody;
    const token = tokenResponse.cookie_based_tokens;
    const temporaryAccessTokenObject = {
      temporary_access_tokens: token,
    };

    setAccessToken(temporaryAccessTokenObject);
  } catch (err) {
    /* empty */
  }
  const token = getAccessToken();

  if (token?.token) {
    handleRedirects();
  }
  return;
};

export const handleRedirects = () => {
  const { redirect } = parse(window.location.search);
  const redirectHref = `${window.location.origin}${redirect || ""}`;
  // In development, we want to redirect urls
  // locally as we don't know if they are external.
  if (redirectHref && getConfig().shared.enableDevelopmentRoute) {
    window.location.replace(redirectHref);
  } else {
    /*
      To handle redirects after sign in for both merchant dashboard and enterprise dashboard
      we do the following:

      When any unauthenticated user tries to access a page they get sent to the sign in page in MD.
      After logging in successfully we check for the presence of the redirect query param:

      > If present we know to redirect the user with that value internally using within MD.
      > If not present we navigate the user to the enterprise dashboard with a query param to instruct ED
        that this user needs to be redirected to the page they wanted to visit initially before having to sign in.
    */

    if (redirect) {
      externalNavigation(redirectHref, false);
    } else {
      navigateHome({ redirectWithRouterState: "true" });
    }
  }
};

export function login(
  accessTokenResponse?: TemporaryAccessTokenCreateResponseBody
) {
  setAccessToken(accessTokenResponse);
  removeItem("gc.top-banner-closed");
  handleRedirects();
}

export function useAccessToken(): [
  AccessToken,
  (accessTokenResponse?: AccessTokenResponse) => void,
] {
  const [accessToken, updateAccessToken] = useState<AccessToken>(
    // Get an access token from localStorage
    // Will be null if the user is not logged in
    getAccessToken()
  );

  // Update the @gocardless/api package synchronously to make sure the token is available immediately
  addAccessToken(accessToken?.token || "");

  // Subscribe to changes to the token in localStorage
  useEffect(() => {
    const updateAccessTokenListenerEvent = (event: StorageEvent) => {
      if (event.key === GC_ACCESS_TOKEN_KEY) {
        try {
          const accessTokenRequest = JSON.parse(event.newValue || "{}");
          updateAccessToken(accessTokenRequest);
        } catch {
          /* empty */
        }
      }
    };

    if (!window) return;

    window.addEventListener("storage", updateAccessTokenListenerEvent);

    return () => {
      window.removeEventListener("storage", updateAccessTokenListenerEvent);
    };
  }, []);

  return [accessToken, setAccessToken];
}

import { i18n } from "@lingui/core";
import { selectOrdinal, t } from "@lingui/macro";

export const getDayOfMonth = (day: number): string =>
  day === 29 || day === -1
    ? i18n._(t({ id: "last-day", message: "last day" }))
    : getDayOfMonthOrdinal(day);

export const getDayOfMonthOrdinal = (day: number): string =>
  i18n._(
    t({
      id: "day-of-month-ordinal",
      message: selectOrdinal(day, {
        one: "#st",
        two: "#nd",
        few: "#rd",
        many: "#th",
        other: "#th",
      }),
    })
  );

export const getLastDayOfMonth = (month: number, year: number): number => {
  const isLeapYear =
    Number.isInteger(year / 400) ||
    (Number.isInteger(year / 4) && !Number.isInteger(year / 100));
  const lastDayOfMonth = [
    31,
    isLeapYear ? 29 : 28,
    31,
    30,
    31,
    30,
    31,
    31,
    30,
    31,
    30,
    31,
  ];
  return lastDayOfMonth[month] || 30;
};

import { OptimizelyContext } from "@optimizely/react-sdk";
import { createContext, useContext, useEffect, useState } from "react";
import { OptimizelyFlag } from "src/technical-integrations/optimizely/constants";

/**
 * A list of experiments we want to fire once on every user login/session to
 * ensure that a decision is _always_ made for these experiments regardless of
 * the pages that the user visits.
 */
const GLOBAL_EXPERIMENT_DECISIONS: OptimizelyFlag[] = [
  OptimizelyFlag.SUBSCRIPTION_TEMPLATES_NAMING,
  OptimizelyFlag.SPARK_DOUBLE_REWARDS_PROMOTION,
];

export const GlobalExperimentDecisionContext = createContext<{
  hasMadeGlobalExperimentDecisions: boolean;
  setHasMadeGlobalExperimentDecisions: (value: boolean) => void;
}>({
  hasMadeGlobalExperimentDecisions: false,
  setHasMadeGlobalExperimentDecisions: () => {},
});

export const GlobalExperimentDecisionProvider: React.FC<{
  children: React.ReactNode;
}> = ({ children }) => {
  const [
    hasMadeGlobalExperimentDecisions,
    setHasMadeGlobalExperimentDecisions,
  ] = useState(false);

  const value = {
    hasMadeGlobalExperimentDecisions,
    setHasMadeGlobalExperimentDecisions,
  };

  return (
    <GlobalExperimentDecisionContext.Provider value={value}>
      {children}
    </GlobalExperimentDecisionContext.Provider>
  );
};

/**
 * A component which ensures that we always fire a decision event at least once
 * for experiments which we always want to fire when a user logs into the
 * dashboard regardless of the page they visit. Note that we always need this
 * component to be placed inside of the Optimizely and Segment contexts to
 * ensure that the Optimizely decision and the Segment event tracking fired as a
 * side-effect of the decision will be fired.
 */
export const GlobalExperimentDecisions: React.FC = () => {
  const {
    hasMadeGlobalExperimentDecisions,
    setHasMadeGlobalExperimentDecisions,
  } = useContext(GlobalExperimentDecisionContext);
  const { optimizely } = useContext(OptimizelyContext);

  useEffect(() => {
    if (!hasMadeGlobalExperimentDecisions) {
      optimizely?.decideForKeys(GLOBAL_EXPERIMENT_DECISIONS);
      setHasMadeGlobalExperimentDecisions(true);
    }
  }, [
    hasMadeGlobalExperimentDecisions,
    optimizely,
    setHasMadeGlobalExperimentDecisions,
  ]);

  return null;
};

import { useUserShowSelf } from "@gocardless/api/dashboard/user";
import {
  OrganisationDetailsScope,
  UserResource,
} from "@gocardless/api/dashboard/types";

const isAdmin = (users?: UserResource) =>
  users?.scope === OrganisationDetailsScope.Admin;

const AdminRestriction: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const { data } = useUserShowSelf();

  if (data === undefined) {
    return null;
  }

  if (!isAdmin(data.users)) {
    return null;
  }

  return <>{children}</>;
};

export default AdminRestriction;

import { createContext, ReactNode, useState, useCallback, useRef } from "react";
import dynamic from "next/dynamic";
import { TrackingEvent } from "src/common/trackingEvents";
import { useUserShowSelf } from "@gocardless/api/dashboard/user";
import { getConfig } from "src/common/config";
import { MerchantOnboardingSetupEvents } from "src/components/routes/Setup/common/constants/MerchantOnboardingSetupEvents";
import { ContentCards } from "@braze/web-sdk";

export interface BrazeContextType {
  sendCustomEvent: (
    eventName: TrackingEvent | MerchantOnboardingSetupEvents,
    eventProperties?: {}
  ) => void;
  getBrazeInstance: () => BrazeInstance;
  loadContentCards: () => Promise<ContentCards>;
  cleanupBrazeSubscription: () => void;
}

export type BrazeInstance = typeof import("@braze/web-sdk") | null;

export const BrazeContext = createContext<BrazeContextType | undefined>(
  undefined
);

export const BrazeProvider = ({ children }: { children: ReactNode }) => {
  const subscriptionIdRef = useRef<string | undefined>(undefined);

  const { data } = useUserShowSelf() || {};
  const config = getConfig().client;
  const [brazeInstance, setBrazeInstance] = useState<BrazeInstance>(null);
  const [cachedCards, setCachedCards] = useState<ContentCards | null>(null);

  const initializeBraze = useCallback(
    async (braze: BrazeInstance, userId: string | null) => {
      const { sdkEndpoint, apiKey } = config.braze;
      if (!apiKey || !sdkEndpoint || !userId || !braze || braze.isInitialized())
        return;

      braze.initialize(apiKey, {
        baseUrl: sdkEndpoint,
        allowUserSuppliedJavascript: true,
      });

      braze.changeUser(userId);
      braze.automaticallyShowInAppMessages();

      subscriptionIdRef.current = braze.subscribeToContentCardsUpdates(
        (contentCards) => {
          setCachedCards(contentCards);
        }
      );

      braze.openSession();
    },
    [config.braze]
  );

  /**
   * Braze SDK can only run client side as it requires the window
   * This dynamically imports the BrazeComponent and disables SSR
   */
  const Braze = dynamic(
    async () => {
      const braze = await import("@braze/web-sdk");
      if (data?.users?.id) {
        setBrazeInstance(braze);
        initializeBraze(braze, data.users.id);
      }
      return () => null;
    },
    { ssr: false }
  );

  const sendCustomEvent: BrazeContextType["sendCustomEvent"] = useCallback(
    (eventName, eventProperties) => {
      if (!brazeInstance) return;
      brazeInstance.logCustomEvent(eventName, eventProperties);
    },
    [brazeInstance]
  );

  const getBrazeInstance: BrazeContextType["getBrazeInstance"] = useCallback(
    () => brazeInstance,
    [brazeInstance]
  );

  const loadContentCards = async (): Promise<ContentCards> => {
    if (cachedCards && cachedCards.cards.length > 0) {
      return cachedCards;
    }

    return new Promise((resolve) => {
      brazeInstance?.requestContentCardsRefresh(() => {
        resolve(cachedCards as ContentCards);
      });
    });
  };

  const cleanupBrazeSubscription = (): void => {
    if (subscriptionIdRef && subscriptionIdRef.current) {
      brazeInstance?.removeSubscription(subscriptionIdRef.current);
      subscriptionIdRef.current = undefined;
    }
  };

  return (
    <BrazeContext.Provider
      value={{
        sendCustomEvent,
        getBrazeInstance,
        loadContentCards,
        cleanupBrazeSubscription,
      }}
    >
      <Braze />
      {children}
    </BrazeContext.Provider>
  );
};

import { usePrimaryCreditor } from "src/queries/creditor";
import { useOrganisation } from "src/queries/organisation";

/**
 * This hook replaces conditions defined in Optimizely feature flag for referral
 * scheme. https://app.optimizely.com/v2/projects/21104440246/flags/manage/turbo_growth__referral_scheme_v2/view 
 * valid_conditions = {
    account_type: "!sales_served",
    verification_status: "successful",
    referral_scheme_enabled: true,
    is_payment_provider: false,
    geo: "!DE",
  };
**/

export const useIsEligibleForReferral = (): Boolean => {
  const organisation = useOrganisation();
  const creditor = usePrimaryCreditor();

  if (!organisation || !creditor) return false;

  const isNotSalesServed = organisation.account_type !== "sales_served";
  const isVerified = creditor.verification_status === "successful";
  const isReferralSchemeEnabled = Boolean(organisation.referral_scheme_enabled);
  const isNotAPaymentProvider = !organisation.links?.payment_provider;
  const isGeoEligible = creditor.geo !== "DE";

  return (
    isNotSalesServed &&
    isVerified &&
    isReferralSchemeEnabled &&
    isNotAPaymentProvider &&
    isGeoEligible
  );
};

import { useContext, useEffect, useRef } from "react";
import { Box, ColorPreset, Visibility } from "@gocardless/flux-react";
import { useResizeObserver } from "src/utils/geometry";

import { GoCardlessStaffRestriction } from "../restrictions";
import ImpersonationBanner from "../ImpersonationBanner";
import { SandboxBanner } from "../layout/SandBoxBanner";
import VerificationBanner from "../verification-banner/VerificationBanner";
import MaintenanceNotice from "../maintenance-notice/MaintenanceNotice";
import { GlobalState } from "../global-state/GlobalStateProvider";
import { OrganisationBanner } from "../routes/Accounts/OrganisationBanner.tsx/OrganisationBanner";
import { MultiAccountRestriction } from "../routes/Accounts/MultiAccountRestriction/MultiAccountRestriction";

const Banners = () => {
  const { setBannersHeight, showLayoutBanners } = useContext(GlobalState);
  const bannersRef = useRef<HTMLInputElement | null>(null);

  const resizeCallback = () => {
    const element = bannersRef.current;
    const rect = element?.getBoundingClientRect();

    setBannersHeight(rect ? rect.height : element?.offsetHeight || 0);
  };

  useResizeObserver(bannersRef, resizeCallback, []);
  useEffect(resizeCallback, [setBannersHeight]);

  return (
    <Box
      ref={bannersRef}
      css={{
        position: "fixed",
        top: 0,
        left: 0,
        width: "100%",
        zIndex: 350,
      }}
      bg={ColorPreset.BackgroundLight_02}
    >
      {/* These banners are high level banners which are visible on every page */}
      <GoCardlessStaffRestriction>
        <ImpersonationBanner />
      </GoCardlessStaffRestriction>
      <SandboxBanner />

      <MultiAccountRestriction>
        <OrganisationBanner />
      </MultiAccountRestriction>

      {/* These banners are only available inside Primary page layout */}
      {showLayoutBanners ? (
        <>
          <Visibility visible={[null, null, null, "block"]}>
            <Box>
              <VerificationBanner forIframe={false} />
            </Box>
          </Visibility>
          <MaintenanceNotice />
        </>
      ) : null}
    </Box>
  );
};

export default Banners;
